<template>
  <div class="examInformation">
    <img class="examInformation-img" src="@/assets/image/examination/exam1.png" alt="">
    <div class="examInformation-box">
      <div class="examInformation-box-div">
        <img src="@/assets/image/examination/head-icon1.png" alt="">
        准考證號碼： <span class="span1">{{ admissionTicket }}</span>
      </div>
      <div class="examInformation-box-div">
        <img src="@/assets/image/examination/head-icon2.png" alt="">
        報考級數： <span class="span2">{{ entryLevel }}</span>
      </div>
      <div class="examInformation-box-div">
        <img src="@/assets/image/examination/head-icon3.png" alt="">
        {{ test }}： <span class="span3">{{ examCountdown }}</span>
      </div>
      <div class="examInformation-box-record">
        <span>正在分享螢幕畫面...</span>
      </div>
      <!-- <div class="examInformation-box-record" v-if="shareType == 1 || shareType == 2">
        <span v-if="shareType == 1">正在分享螢幕畫面...</span>
        <span v-if="shareType == 2">已停止分享螢幕畫面...</span>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  name: 'ExamInformation',
  props: {
    test: {
      type: String,
      default: '剩餘測驗時間'
    }
  },
  computed: {
    ...mapGetters(['shareType', 'admissionTicket', 'entryLevel', 'examCountdown'])
  }
}
</script>

<style lang="less" scoped>
.examInformation {
  width: 1320px;
  height: 50px;
  margin: 0 auto;
  background: linear-gradient(180deg, #FBE8BF 0%, #FFFFFF 100%);
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  .examInformation-img {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
  .examInformation-box {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 320px 0 100px;
    width: calc(100% - 430px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
    .examInformation-box-div {
      display: flex;
      align-items: center;
      font-family: "Microsoft JhengHei","微軟正黑體";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 27px;
      color: #745E3E;
      img {
        margin-right: 6px;
        width: 24px;
        height: 24px;
      }
      .span1 {
        width: 90px;
      }
      .span2 {
        width: 20px;
      }
      .span3 {
        width: 50px;
      }
    }
    .examInformation-box-record {
      user-select: none;
      position: absolute;
      top: 0;
      right: -10px;
      width: 181px;
      height: 50px;
      background: #F0AF3A;
      border-radius: 8px;
      font-family: "Microsoft JhengHei","微軟正黑體";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>